import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core'
import {ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, Router} from '@angular/router'
import {Block, CompanyAddressData, GridBlockData, ImageData, TextImageData} from '../block'
import {filter, takeUntil} from 'rxjs/operators'
import {Subject, Subscription} from 'rxjs'
import {PageService} from '../../../services/page.service'
import {HeadTagsService} from '../../../services/head-tags.service';
import {GridService} from './grid.service';
import {SanitizeUrlPipe} from '../../../pipes/sanitize-url.pipe';
import {TextBlockComponent} from './block-component/text-block-component/text-block.component';
import {DownloadsBlockComponent} from './block-component/downloads-block-component/downloads-block.component';
import {SliderBlockComponent} from './block-component/slider-block-component/slider-block.component';
import {GalleryBlockComponent} from './block-component/gallery-block-component/gallery-block.component';
import {
    CompanyAddressBlockComponent
} from './block-component/company-address-block-component/company-address-block.component';
import {TextImageBlockComponent} from './block-component/text-image-block-component/text-image-block.component';
import {
    MenuItemListBlockComponent
} from './block-component/menu-item-list-block-component/menu-item-list-block.component';
import {ImageBlockComponent} from './block-component/image-block-component/image-block.component';
import {NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';

@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgSwitch, NgSwitchCase, ImageBlockComponent, MenuItemListBlockComponent, TextImageBlockComponent, CompanyAddressBlockComponent, GalleryBlockComponent, SliderBlockComponent, DownloadsBlockComponent, NgSwitchDefault, TextBlockComponent, SanitizeUrlPipe]
})

export class GridComponent implements OnDestroy, OnInit {

    @Input() isBeforeFold = false
    @Input() maxColumns = 4
    @Input() type?: 'article' | 'aside' | 'product'
    @Input() location: 'right' | 'left' | 'footer' | 'main' = 'main'
    @Input() gridID: number | null = null
    @Input() blocks: Block[] = []
    @Input() excludeBlocks?: Array<'image' | 'text' | 'contact' | 'company-address' | 'product-category' | 'menu-item-list'>
    gridTemplateColumnsStyles: { [key: number]: string } = {
        1: 'minmax(100%, 1fr)',
        2: 'repeat(2, minmax(48.5%, 1fr))',
        3: 'repeat(3, minmax(31.5%, 1fr))',
        4: 'repeat(4, minmax(23.5%, 1fr))'
    }
    grid: number[][] = []
    maxRow = 0
    destroyed = new Subject<any>()
    subs: Subscription[] = []

    constructor(
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public pageService: PageService,
        public gridService: GridService,
        public headTagsService: HeadTagsService,
        public cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit() {
        this.initPage()
        // stop reloading other grids on url change
        if (this.type !== 'aside') {
            this.router.events.pipe(
                // reload when router is updated or navigation ends
                filter(event => event instanceof NavigationEnd || event instanceof RouteConfigLoadEnd),
                takeUntil(this.destroyed)
            ).subscribe((event) => {
                this.initPage()
            })
        }
    }

    ngOnDestroy() {
        this.destroyed.next(0)
        this.destroyed.complete()
        this.subs.forEach(s => s.unsubscribe())
    }

    initPage() {
        if (this.type === 'article') {
            const pageAlias = this.activatedRoute.snapshot.paramMap.get('alias') || 'home';
            this.subs.push(this.pageService.getByAlias(pageAlias).subscribe((pageData) => {
                    this.headTagsService.setTitle(pageData.title)
                    this.gridID = pageData.id
                    this.blocks = pageData.blocks || []
                    this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
                    this.cdRef.markForCheck()
                })
            )
        } else if (this.type === 'aside') {
            this.subs.push(this.pageService.getBlocksByLocation(this.location).subscribe((gridData: GridBlockData) => {
                    this.blocks = gridData.blocks
                    this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
                    this.cdRef.markForCheck()
                })
            )
        } else if (this.type === 'product') {
            // blocks and gridID (productID) is set through @Input
            this.gridService.initMatrix(this.blocks, this.grid, this.maxRow)
            this.cdRef.markForCheck()
        }
    }

    getImageData(data: any): ImageData {
        return data as ImageData;
    }

    getTextImageData(data: any): TextImageData {
        return data as TextImageData;
    }

    getLinkData(data: any): { link: string } | undefined {
        return (data ? (data as { link: string }) : undefined)
    }

    getCompanyAddressData(data: any): CompanyAddressData {
        return data as CompanyAddressData;
    }

    getTextData(data: any): string {
        return data as string;
    }

    getText(block: any): string {
        return block.data.text || '';
    }
}
